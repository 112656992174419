<template>
  <div class="eidtokencloud-flex-col page">
    <div class="container" ref="video-container" v-if="showVideo">
      <div class="video-wrapper" ref="video-wrapper" :style="videoStyle" v-if="showVideo">
        <video ref="videoPlayer" controls autoplay loop @click="pauseVideo" v-if="showVideo">
          <source :src="videoUrl" type="video/mp4" v-if="showVideo">
        </video>
        <button class="close-button cursors" @click="closeVideo">X</button>
      </div>
    </div>
    <div class="eidtokencloud-flex-col">
      <div class="eidtokencloud-flex-row pad">
      <div class="eidtokencloud-flex-row eidtokencloud-items-center eidtokencloud-flex-auto eidtokencloud-relative section100">
      </div>
      <div
        class="eidtokencloud-flex-col eidtokencloud-items-center eidtokencloud-shrink-0 eidtokencloud-self-center group_200"
      >
        <span class="font_100">令牌云</span>
        <span class="font_100">让数字身份真实可信</span>
        <div class="eidtokencloud-flex-row eidtokencloud-items-center group_300 space-x-3400">
          <div class="eidtokencloud-flex-col eidtokencloud-items-start eidtokencloud-shrink-0 space-y-1600">
            <div class="eidtokencloud-flex-row eidtokencloud-items-center">
              <img class="image_400" src="../../assets/yellowhook2.png" />
              <span class="font_200">提升业务效率</span>
            </div>
            <div class="eidtokencloud-flex-row eidtokencloud-items-center">
              <img class="image_400" src="../../assets/yellowhook2.png" />
              <span class="font_200">信息准确合规</span>
            </div>
            <div class="eidtokencloud-flex-row eidtokencloud-items-center">
              <img class="image_400" src="../../assets/yellowhook2.png" />
              <span class="font_201 text100">防御AI攻击</span>
            </div>
          </div>
        </div>
        <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center text-wrapper100 cursors" :style="{ backgroundColor : bkgColor5}" @click="scrollToSection('section4')" @mouseover="Zixunmouseover" @mouseout="Zixunmouseout" @mousedown="Zixunmousedown" @mouseup="Zixunmouseup">
          <span class="font_202 text_200 cursors" @click="scrollToSection('section4')">点击咨询</span>
        </div>
      </div>
      <div
        class="eidtokencloud-flex-row eidtokencloud-items-center eidtokencloud-shrink-0 eidtokencloud-self-start eidtokencloud-relative group_500"
      >
        <img class="image_600" src="../../assets/whitetopleft.png" />
        <img class="image_700" src="../../assets/whitetopright.png" />
        <img class="image_500 pos_200" src="../../assets/whitecloud.png" />
      </div>
    </div>

      <div class="eidtokencloud-flex-col">
        <div class="eidtokencloud-flex-col eidtokencloud-self-center group_3" ref="section1">
          <div class="eidtokencloud-flex-row group_4" :class="{'Fixed' : isFixed}" ref="Navigation">
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center text-wrapper_2 cursors" @click="scrollToSection('section1')" :style="{ backgroundColor : bkgColor1}" @mouseover="Navmouseover1" @mouseout="Navmouseout1" @mousedown="Navmousedown1" @mouseup="Navmouseup1">
              <span class="font_3" :style="{ color : textcolor1}">产品展示</span>
            </div>
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center text-wrapper_3 cursors" @click="scrollToSection('section2')" :style="{ backgroundColor : bkgColor2}" @mouseover="Navmouseover2" @mouseout="Navmouseout2" @mousedown="Navmousedown2" @mouseup="Navmouseup2">
              <span class="font_3" :style="{ color : textcolor2}">合作客户</span>
            </div>
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center text-wrapper_3 cursors" @click="scrollToSection('section3')" :style="{ backgroundColor : bkgColor3}" @mouseover="Navmouseover3" @mouseout="Navmouseout3" @mousedown="Navmousedown3" @mouseup="Navmouseup3">
              <span class="font_3" :style="{ color : textcolor3}">关于我们</span>
            </div>
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center text-wrapper_4 cursors" @click="scrollToSection('section4')" :style="{ backgroundColor : bkgColor4}" @mouseover="Navmouseover4" @mouseout="Navmouseout4" @mousedown="Navmousedown4" @mouseup="Navmouseup4">
              <span class="font_3" :style="{ color : textcolor4}">联系我们</span>
            </div>
          </div>
          <div class="eidtokencloud-flex-col eidtokencloud-items-center group_5 space-y-22">
            <span class="font_4">我们的产品 优势一目了然</span>
            <span class="font_4">助您轻松决定如何采用</span>
          </div>

          <div class="eidtokencloud-flex-row group_6 space-x-122">
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-shrink-0 eidtokencloud-relative group_7" @mouseover="startmouseover" @mouseout="startmouseout" @mousedown="startmousedown" @mouseup="startmouseup">
              <div class="eidtokencloud-flex-col section_2 space-y-20 cursors" :class="{ outerborder : isHover}" @click="playVideo1">
                <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center section_4" :style="{ backgroundImage: 'url(' + background + ')'}">
                  <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper" :style="{ backgroundColor: startbkg}" @mouseover="startmouseover1" @mouseout="startmouseout1" @mousedown="startmousedown1" @mouseup="startmouseup1">
                    <img class="image_7" src="../../assets/whitetriangle.png" />    
                  </div>
                </div>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv1">身份证芯片认证</span>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv2">全球护照芯片认证</span>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv3">TMFA高可信设备安全</span>
              </div>
              <img class="image_6 pos_2" :src="currentSymbol" />
            </div>

            <div>
              <div class="eidtokencloud-flex-col eidtokencloud-flex-auto" v-bind:class="{show: showDiv1}">
                <span class="eidtokencloud-self-start font_5" v-show="showDiv1">主要适用场景</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_8 space-x-16" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_6" v-show="showDiv1">金融在线开户、线下移动展业</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_9 space-x-16" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_6 text_4" v-show="showDiv1">身份信息的采集、更新</span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_5" v-show="showDiv1">对比“拍照识别身份证”</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_10 space-x-16" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">更快：仅需3秒，用户体验&amp;业务成功率大幅提升</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_11 space-x-16" v-show="showDiv1">
                  <div class="eidtokencloud-shrink-0 section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">更真：身份证件保真，信息完整度、准确度100%，杜绝恶意造假</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_12 space-x-16" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">更省：信息权威合规，自带人脸比对，节约客户成本</span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_6" v-show="showDiv1">令牌云的实施优势</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_13 space-x-18" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">国内稀缺的业务资质，每笔认证均附带公安数字签名</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_14 space-x-18" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">已封装符合行业最佳实践的UI交互，显著提升流程成功率</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_15 space-x-18" v-show="showDiv1">
                  <div class="section_3" v-show="showDiv1"></div>
                  <span class="font_7" v-show="showDiv1">标准化产品+灵活定制能力，最快1天即可对接上线</span>
                </div>
              </div>


              <div class="eidtokencloud-flex-col eidtokencloud-flex-auto" v-bind:class="{show: showDiv2}">
                <span class="eidtokencloud-self-start font_5" v-show="showDiv2">主要适用场景</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_8 space-x-16" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_6" v-show="showDiv2">金融在线开户</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_9 space-x-16" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_6 text_4" v-show="showDiv2">酒店接待外宾</span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_5" v-show="showDiv2">对比“拍照识别护照”</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_10 space-x-16" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">顺畅：用户操作顺畅，100%解决因护照表面覆膜而无法完成识别的情况</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_11 space-x-16" v-show="showDiv2">
                  <div class="eidtokencloud-shrink-0 section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">准确：业内已知唯一的、可以准确识别和认证护照信息的方法</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_12 space-x-16" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">真实：可获取高清头像，进一步保障持证人的真实性</span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_6" v-show="showDiv2">令牌云的实施优势</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_13 space-x-18" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">业内稀缺的全球根证书验证能力，鉴别护照芯片真伪</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_14 space-x-18" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">多国护照和港澳台通行证实测，产品可用性国际领先</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_15 space-x-18" v-show="showDiv2">
                  <div class="section_3" v-show="showDiv2"></div>
                  <span class="font_7" v-show="showDiv2">可断网运行，保障数据严格合规，不出客户本地系统</span>
                </div>
              </div>

              <div class="eidtokencloud-flex-col eidtokencloud-flex-auto" v-bind:class="{show: showDiv3}">
                <span class="eidtokencloud-self-start font_5" v-show="showDiv3">主要适用场景</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_8 space-x-16" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_6" v-show="showDiv3">手机银行APP的账号安全登录&amp;交易安全保障</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_9 space-x-16 divsize" v-show="showDiv3">
                  <span class="font_6 text_4" v-show="showDiv3"></span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_5" v-show="showDiv3">对比“设备指纹”</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_10 space-x-16" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">合规：无需获取任何用户信息和权限，避免个保合规问题</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_11 space-x-16" v-show="showDiv3">
                  <div class="eidtokencloud-shrink-0 section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">精准：直达手机芯片，黑产无法破解伪装成“系统已信任手机”</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_12 space-x-16" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">便捷：提供可信设备绑定能力，让正常用户操作更简单更安全</span>
                </div>
                <span class="eidtokencloud-self-start font_5 text_6" v-show="showDiv3">令牌云的实施优势</span>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_13 space-x-18" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">行业独家解决方案，帮助银行无需过多依赖人脸识别</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_14 space-x-18" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">仅需一套方案，覆盖手机银行身份认证的全部场景</span>
                </div>
                <div class="eidtokencloud-flex-row eidtokencloud-items-center group_15 space-x-18" v-show="showDiv3">
                  <div class="section_3" v-show="showDiv3"></div>
                  <span class="font_7" v-show="showDiv3">帮助银行长期积累设备风控大数据，有效描绘用户关系图谱</span>
                </div>
              </div>
            </div>

            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-shrink-0 eidtokencloud-relative group_7" @mouseover="startmouseover2" @mouseout="startmouseout2" @mousedown="startmousedown2" @mouseup="startmouseup2">
              <div class="eidtokencloud-flex-col section_2 space-y-20 cursors" :class="{ outerborder : isHover2}" @click="playVideo2">
                <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center section_4" :style="{ backgroundImage: 'url(' + background2 + ')'}">
                  <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper" :style="{ backgroundColor: startbkg2}" @mouseover="startmouseover3" @mouseout="startmouseout3" @mousedown="startmousedown3" @mouseup="startmouseup3">
                    <img class="image_7" src="../../assets/whitetriangle.png" />    
                  </div>
                </div>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv3">身份证芯片认证</span>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv1">全球护照芯片认证</span>
                <span class="eidtokencloud-self-center font_5 text_7" v-show="showDiv2">TMFA高可信设备安全</span>
              </div>
              <img class="image_6 pos_2 cursors" :src="currentSymbol2" @click="change3()"/>
            </div>
          </div>
          
          <div class="eidtokencloud-flex-row eidtokencloud-justify-between eidtokencloud-items-center group_16">
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-start section_5 cursors" id="Navline" @click="change4($event)">
              <div class="section_6" :class="{'left1' : showDiv1, 'left2' : showDiv2, 'left3' : showDiv3}"  ref="myDiv"></div>
            </div>
            <div class="eidtokencloud-flex-row space-x-18">
              <img class="image_8 cursors" :src="leftimg" v-on:click="change2()" @mouseover="Limgmouseover" @mouseout="Limgmouseout" @mousedown="Limgmousedown" @mouseup="Limgmouseup"/>
              <img class="image_8 cursors" :src="rightimg" v-on:click="change1()" @mouseover="Rimgmouseover" @mouseout="Rimgmouseout" @mousedown="Rimgmousedown" @mouseup="Rimgmouseup"/>
            </div>
          </div>
        </div>
        <div class="eidtokencloud-flex-col section_7 space-y-82" ref="section2">
          <div class="eidtokencloud-flex-col eidtokencloud-items-center space-y-22">
            <span class="font_4">我们的专业服务</span>
            <span class="font_4 text_8">从世界500强到初创公司都认可</span>
          </div>
          <div class="eidtokencloud-flex-col eidtokencloud-items-center space-y-24">
            <div class="eidtokencloud-flex-row eidtokencloud-justify-between size">
              <img class="image_9" src="../../assets/jingdong.png" />
              <img class="image_9" src="../../assets/yillionbank.png" />
              <img class="image_9" src="../../assets/Invix.png" />
              <img class="image_9" src="../../assets/Industrial.png" />
              <img class="image_9" src="../../assets/Digitech360.png" />
            </div>
            <div class="eidtokencloud-flex-row eidtokencloud-justify-between size">
              <img class="image_9" src="../../assets/Vcredit.png" />
              <img class="image_9" src="../../assets/Gomeeasecard.png" />
              <img class="image_9" src="../../assets/Duxiaoman.png" />
              <img class="image_9" src="../../assets/NeoBankIN.png" />
              <img class="image_9" src="../../assets/WoPaid.png" />
            </div>
            <div class="eidtokencloud-flex-row eidtokencloud-justify-between size">
              <img class="image_9" src="../../assets/SPDbank.png" />
              <img class="image_9" src="../../assets/Lakala.png" />
              <img class="image_9" src="../../assets/AWS.png" />
              <img class="image_9" src="../../assets/licaimofan.png" />
              <img class="image_9" src="../../assets/Geerong.png" />
            </div>
            <div class="eidtokencloud-flex-row eidtokencloud-justify-between size">
              <img class="image_9" src="../../assets/SPMedical.png" />
              <img class="image_9" src="../../assets/yangqianguang.png" />
              <img class="image_9" src="../../assets/PGYER.png" />
              <img class="image_9" src="../../assets/langchao.png" />
              <img class="image_9" src="../../assets/andsoon.png" />
            </div>
          </div>
        </div>
        <div class="eidtokencloud-flex-col eidtokencloud-self-center group_17 space-y-88">
          <div class="eidtokencloud-flex-col eidtokencloud-items-center space-y-22">
            <span class="font_4 text_9">与他们一起</span>
            <span class="font_4 text_10">体验全新的身份认证服务吧</span>
          </div>
          <div class="eidtokencloud-flex-row eidtokencloud-justify-between">
            <div class="eidtokencloud-flex-row eidtokencloud-self-start group_18 space-x-134">
              <div class="eidtokencloud-flex-col eidtokencloud-items-center group_19 space-y-68">
                <span class="font_5 cursors" @click="eximg1" v-bind:class="{'text_11' : isText1}">电子政务场景</span>
                <span class="font_5 cursors" @click="eximg2" v-bind:class="{'text_11' : isText2}">银行机构场景</span>
                <span class="font_5 cursors" @click="eximg3" v-bind:class="{'text_11' : isText3}">消费金融场景</span>
                <span class="font_5 cursors" @click="eximg4" v-bind:class="{'text_11' : isText4}">证照扫描场景</span>
              </div>
              <div class="eidtokencloud-flex-row">
                <div class="section_8"></div>
                <div class="eidtokencloud-self-start section_9" :class="{'marginTop1' : isText1, 'marginTop2' : isText2, 'marginTop3' : isText3, 'marginTop4' : isText4}"></div>
              </div>
            </div>
            <img class="image_10" :src="currentImage" />
          </div>
        </div>
        <div class="eidtokencloud-flex-col section10 spacey84" ref="section3">
          <div class="eidtokencloud-flex-col eidtokencloud-items-center eidtokencloud-self-center spacey22">
            <span class="font5 text14">我们的团队理念</span>
            <span class="font5 text15">让优秀与优秀为伍</span>
          </div>
          <div class="eidtokencloud-flex-row eidtokencloud-justify-center spacex32">
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-relative group20">
              <div class="eidtokencloud-flex-col section11">
                <span class="eidtokencloud-self-start font8">团队实力</span>
                <div class="eidtokencloud-flex-col group21 margintop14">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">由北大清华Boss带领的初创团队，一同</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">冲锋陷阵，在实战中学习进步</span>
                </div>
                <div class="eidtokencloud-flex-col group21 margintop17">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">团队氛围融洽，互为师长，终身学习，争</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">做深藏不漏的多面手</span>
                </div>
              </div>
              <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper18 pos3">
                <img class="eidtokencloud-shrink-0 image28" src="../../assets/teambk.png" />
              </div>
            </div>
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-relative group20">
              <div class="eidtokencloud-flex-col section11">
                <span class="eidtokencloud-self-start font8">技术领先</span>
                <div class="eidtokencloud-flex-col group21 margintop14">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22 spacex6">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">我们拥有占比过半的全栈开发人员，建立</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">雄厚的技术支撑</span>
                </div>
                <div class="eidtokencloud-flex-col group21 margintop17">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">具备行业视角和积极的思维模式，在可信</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">计算、隐私保护等创新应用上独树一帜</span>
                </div>
              </div>
              <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper19 pos3">
                <img class="image29" src="../../assets/skillbk.png" />
              </div>
            </div>
            <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-relative group20">
              <div class="eidtokencloud-flex-col section11">
                <span class="eidtokencloud-self-start font8">价值向善</span>
                <div class="eidtokencloud-flex-col group21 margintop14">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22 spacex6">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">我们为客户用户的信息安全保驾护航，不</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">行投机事，不赚亏心钱</span>
                </div>
                <div class="eidtokencloud-flex-col group21 margintop17">
                  <div class="eidtokencloud-flex-row eidtokencloud-items-center group22">
                    <div class="eidtokencloud-shrink-0 section13"></div>
                    <span class="eidtokencloud-flex-auto font3 text16">对伙伴真心相待，不PUA不内卷，在有限</span>
                  </div>
                  <span class="eidtokencloud-self-start text21 font3">条件内最大化人的价值，维护公平和正义</span>
                </div>
              </div>
              <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper20 pos5">
                <img class="image30" src="../../assets/valuebk.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_20 view space-y-54">
      <div class="eidtokencloud-flex-col eidtokencloud-items-center eidtokencloud-self-center space-y-22">
        <span class="font_4 text_12">积跬步行千里</span>
        <span class="font_4">时间为努力作证</span>
      </div>
      <transition name="slide">
        <div class="imgList" @wheel="handleWheel" @click="handleclick" @mouseleave="handleMouseLeave" ref="imgList">
          <ul>
            <img class="img_1" src="../../assets/picture1.png" />
            <img class="img_1" src="../../assets/picture2.png" />
            <img class="img_1" src="../../assets/picture3.png" />
            <img class="img_1" src="../../assets/picture4.png" />
            <img class="img_1" src="../../assets/picture5.png" />
            <img class="img_1" src="../../assets/picture6.png" />
          </ul>
          <ul>
            <img class="img_1" src="../../assets/picture7.png" />
            <img class="img_1" src="../../assets/picture8.png" />
            <img class="img_1" src="../../assets/picture9.png" />
            <img class="img_1" src="../../assets/picture10.png" />
            <img class="img_1" src="../../assets/picture11.png" />
            <img class="img_1" src="../../assets/picture12.png" />
          </ul>
        </div>
      </transition>
    </div>

    <div class="eidtokencloud-flex-col group_29 space-y-38" ref="section4">
      <div class="eidtokencloud-flex-col space-y-79">
        <div class="eidtokencloud-flex-col section_25 space-y-64">
          <div class="eidtokencloud-flex-col eidtokencloud-items-center space-y-22">
            <span class="font_4 text_38">扫码联系我们</span>
            <span class="font_4 text_39">获得企业专属方案</span>
          </div>
          <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center eidtokencloud-relative group_30">
            <img class="imgcircle" src="../../assets/rotatecircle.png"/>
            <div class="circle-container">
              <div class="slide"></div>
              <div class="nav_circle r1">
                <img class="pos_93 circle1" :class="{'showimgsize2' :  img1isHover, 'showimgsize1' : img1isnotHover}" :src="showimg1" @mouseover="Hovered1" @mouseout="Unhovered1"/>
                <img class="pos_94 circle2" :class="{'showimgsize2' :  img2isHover, 'showimgsize1' : img2isnotHover}" :src="showimg2" @mouseover="Hovered2" @mouseout="Unhovered2"/>
              </div>
            </div>
          </div>
        </div>
        
        <div class="eidtokencloud-flex-col eidtokencloud-self-center space-y-188">
          <div class="eidtokencloud-flex-col space-y-52">
            
            <div class="eidtokencloud-flex-col eidtokencloud-items-center space-y-22">
              <span class="font_4 text_38">体验令牌云身份服务</span>
              <span class="font_4 text_39">推荐通过微信扫一扫打开</span>
            </div>

            <div class="eidtokencloud-flex-row eidtokencloud-relative padleft">
              <div
                class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center eidtokencloud-relative section_900"
              >
                <div class="section_12"></div>
                <div class="section_11 pos_17"></div>
                <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper100 pos_18">
                  <img class="image_25" src="../../assets/3cbb94c0a4ad84b5f5993fb9c2ee1e56.png" />
                </div>
                <img class="image_22 pos_16" src="../../assets/f6a6dbb0c3a286350e410b851c5b67d4.png" />
              </div>
              <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center section_10">
                <div class="eidtokencloud-flex-col eidtokencloud-justify-start eidtokencloud-items-center image-wrapper_2">
                  <img class="image_23" src="../../assets/DemoNav.png" />
                </div>
              </div>
              <img class="eidtokencloud-shrink-0 image_24 pos_19" src="../../assets/67dd877087979c5c1c4a7401925e1369.png" />
            </div>

          </div>
          <span class="font_8">友情链接</span>
        </div>
      </div>
      <div class="eidtokencloud-flex-row eidtokencloud-justify-evenly eidtokencloud-self-center group_39">
        <img class="image_43 cursors" src="../../assets/eIDLINK.png" @click="jump1"/>
        <img class="image_44 cursors" src="../../assets/eIDCN.png" @click="jump2">
        <img class="image_44 cursors" src="../../assets/dianliangziben.png" @click="jump3"/>
        <img class="image_45 cursors" src="../../assets/YuanAngel.png" @click="jump4"/>
        <img class="image_44 cursors" src="../../assets/DIDA.png" @click="jump5"/>
        <div class="img_border cursors" @click="jump6">
          <img class="image_46" src="../../assets/BJFintech.png"/>
        </div>
      </div>
    </div>
    <div class="eidtokencloud-flex-col eidtokencloud-self-center group_40 space-y-38">
      <span class="eidtokencloud-self-center font_16">联系地址</span>
      <div class="eidtokencloud-flex-row eidtokencloud-justify-between space-y-14">
        <div class="eidtokencloud-flex-col eidtokencloud-justify-between eidtokencloud-items-center group_41">
          <img class="image_47" src="../../assets/AddressSymbol.png" />
          <span class="font_17">上海</span>
          <span class="font_18 eidtokencloud-self-center">普陀区叶家宅路100号创享塔东区2层</span>
        </div>
        <div class="eidtokencloud-flex-col eidtokencloud-justify-between eidtokencloud-items-center group_41 space-x-80">
          <img class="image_47" src="../../assets/AddressSymbol.png" />
          <span class="font_17">北京</span>
          <span class="font_18 eidtokencloud-self-center">东城区夕照寺街2号业务综合楼3层</span>
        </div>
        <div class="eidtokencloud-flex-col eidtokencloud-justify-between eidtokencloud-items-center group_41 space-x-80">
          <img class="image_47" src="../../assets/AddressSymbol.png" />
          <span class="font_17">苏州</span>
          <span class="font_18 eidtokencloud-self-center">苏州市相城区芯汇湖大厦3层</span>
        </div>
        <div class="eidtokencloud-flex-col eidtokencloud-justify-between eidtokencloud-items-center group_41 space-x-80">
          <img class="image_48" src="../../assets/EmailSymbol.png" />
          <span class="font_17">邮箱</span>
          <span class="font_20 text_45 eidtokencloud-self-center">service@eidtoken.cn</span>
        </div>
      </div>
    </div>

    <div class="eidtokencloud-flex-col eidtokencloud-items-center section_39 space-y-24">
      <div class="eidtokencloud-flex-row eidtokencloud-items-center">
        <span class="font_19 text_48">Copyright © 2023</span>
        <span class="font_19 text_46">令牌云（上海）科技有限公司</span>
        <img class="eidtokencloud-shrink-0 image_49" src="../../assets/NationalEmblem.png" />
        <span class="font_19">沪公网安备 31011002005919号</span>
        <a class="font_19 text_47" href="http://beian.miit.gov.cn">沪ICP备2020036440号</a>
      </div>
      <span class="font_19">增值电信业务经营许可证：沪B2-20210235</span>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        currentQR: require("../../assets/AndroidQR.png"),
        isText1: true,
        isText2: false,
        isText3: false,
        isText4: false,
        currentImage: require("../../assets/section1.png"),
        showDiv1: true,
        showDiv2: false,
        showDiv3: false,
        background: require("../../assets/IDcardau.png"),
        currentSymbol: require("../../assets/IDcardsymbol.png"),
        background2: require("../../assets/passportau.png"),
        currentSymbol2: require("../../assets/passportsymbol.png"),
        currentAndroid: require("../../assets/AndroidWhite.png"),
        currentHarmony: require("../../assets/HarmonyWhite.png"),
        currentIOS: require("../../assets/iOSWhite.png"),
        isClicked1: true,
        isClicked2: true,
        bgColor1: 'black',
        bgColor2: 'black',
        videoUrl: require("../../assets/IDcard.mp4"),
        videoUrl1: require("../../assets/IDcard.mp4"),
        videoUrl2: require("../../assets/passport.mp4"),
        img1isHover : false,
        img1isnotHover : true,
        img2isHover : false,
        img2isnotHover : true,
        showimg1: require("../../assets/SmallWechat.png"),
        showimg2: require("../../assets/Smallmail.png"),
        textcolor1: "#5e84d9",
        textcolor2: "#5e84d9",
        textcolor3: "#5e84d9",
        textcolor4: "#5e84d9",
        bkgColor1: "#f3f7ff",
        bkgColor2: "#f3f7ff",
        bkgColor3: "#f3f7ff",
        bkgColor4: "#f3f7ff",
        bkgColor5: "#5D80D5",
        leftimg: require("../../assets/leftbutton1.png"),
        rightimg: require("../../assets/rightbutton1.png"),
        isHover: false,
        startbkg: "rgba(0,0,0,0.1)",
        isHover2: false,
        startbkg2: "rgba(0,0,0,0.1)",
        showVideo: false,
        videoStyle: null,
        isScrolling: false,
        isFixed: false,
        offsetTop: 0,
        isMouseClickInTarget: false,
      };
    },
    mounted() {
      // 设置图片列表初始位置
      const imgList = this.$refs.imgList;
      const windowWidth = window.innerWidth;
      imgList.scrollLeft = 30;
      this.$nextTick(()=>{
        const dom = this.$refs.Navigation;
        console.log(dom);
        let offsetTop = dom.offsetTop;
        window.addEventListener(
          'scroll',
          e=>{
            let scrollTop = e.target.scrollTop;
            if(this.timer){
              clearTimeout(this.timer)
            }
            this.timer = setTimeout(()=>{
              //滚动距离为包裹层距离内容层的高度
              if(scrollTop >= offsetTop){
                this.isFixed = true;
              }else{
                this.isFixed = false;
              }
            },10)
          },true
        )
      });
    },

    methods: {
      handleWheel(event){
        if(this.isMouseClickInTarget){
          event.preventDefault();
          const imgList = document.querySelector(".imgList");
          imgList.scrollLeft += event.deltaY;
        }
      },
      handleclick(event){
        event.stopPropagation();
        this.isMouseClickInTarget = true;
      },
      handleMouseLeave() {
        this.isMouseClickInTarget = false;
      },
      showImage1(){
        this.currentQR = require("../../assets/whitebkg1.png");
        setTimeout(() => {
          this.currentQR = require("../../assets/iOSQR.png");
        }, 50);
      },
      showImage2(){
        this.currentQR = require("../../assets/whitebkg1.png");
        setTimeout(() => {
          this.currentQR = require("../../assets/AndroidQR.png");
        }, 50);
      },
      eximg1(event){
        this.isText1 = true;
        this.isText2 = false;
        this.isText3 = false;
        this.isText4 = false;
        this.currentImage = require("../../assets/section1.png");
      },
      eximg2(event){
        this.isText1 = false;
        this.isText2 = true;
        this.isText3 = false;
        this.isText4 = false;
        this.currentImage = require("../../assets/section2.png");
        this.marginTop = '75';
      },
      eximg3(event){
        this.isText1 = false;
        this.isText2 = false;
        this.isText3 = true;
        this.isText4 = false;
        this.currentImage = require("../../assets/section3.png");
        this.marginTop = '150';
      },
      eximg4(event){
        this.isText1 = false;
        this.isText2 = false;
        this.isText3 = false;
        this.isText4 = true;
        this.currentImage = require("../../assets/section4.png");
        this.marginTop = '225';
      },
      change1(){
        const myDiv = this.$refs.myDiv;
        if(this.showDiv1){
          this.showDiv1 = false;
          this.showDiv2 = true;
          this.showDiv3 = false;
          this.background = require("../../assets/passportau.png");
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/passportsymbol.png");
          this.currentSymbol2 = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/passport.mp4");
          this.videoUrl2 = require("../../assets/TMFA.mp4");
        }
        else if(this.showDiv2){
          this.showDiv1 = false;
          this.showDiv2 = false;
          this.showDiv3 = true;
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/TMFA.mp4");
          this.background2 = require("../../assets/IDcardau.png");
          this.currentSymbol2 = require("../../assets/IDcardsymbol.png");
          this.videoUrl2 = require("../../assets/IDcard.mp4");
        }
        else if(this.showDiv3){
          this.showDiv1 = true;
          this.showDiv2 = false;
          this.showDiv3 = false;
          this.background = require("../../assets/IDcardau.png");
          this.currentSymbol = require("../../assets/IDcardsymbol.png");
          this.videoUrl1 = require("../../assets/IDcard.mp4");
          this.background2 = require("../../assets/passportau.png");
          this.currentSymbol2 = require("../../assets/passportsymbol.png");
          this.videoUrl2 = require("../../assets/passport.mp4");
        }
      },
      change2(){
        const myDiv = this.$refs.myDiv;
        if(this.showDiv3){
          this.showDiv1 = false;
          this.showDiv2 = true;
          this.showDiv3 = false;
          this.background = require("../../assets/passportau.png");
          this.currentSymbol = require("../../assets/passportsymbol.png");
          this.videoUrl1 = require("../../assets/passport.mp4");
          this.background2 = require("../../assets/TMFAsafe.png");
          this.currentSymbol2 = require("../../assets/TMFAsymbol.png");
          this.videoUrl2 = require("../../assets/TMFA.mp4");
        }
        else if(this.showDiv2){
          this.showDiv1 = true;
          this.showDiv2 = false;
          this.showDiv3 = false;
          this.background = require("../../assets/IDcardau.png");
          this.currentSymbol = require("../../assets/IDcardsymbol.png");
          this.videoUrl1 = require("../../assets/IDcard.mp4");
          this.background2 = require("../../assets/passportau.png");
          this.currentSymbol2 = require("../../assets/passportsymbol.png");
          this.videoUrl2 = require("../../assets/passport.mp4");
        }
        else if(this.showDiv1){
          this.showDiv1 = false;
          this.showDiv2 = false;
          this.showDiv3 = true;
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/TMFA.mp4");
          this.background2 = require("../../assets/IDcardau.png");
          this.currentSymbol2 = require("../../assets/IDcardsymbol.png");
          this.videoUrl2 = require("../../assets/IDcard.mp4");
        }
      },
      change3(){
        const myDiv = this.$refs.myDiv;
        if(this.showDiv1){
          this.showDiv1 = false;
          this.showDiv2 = true;
          this.showDiv3 = false;
          this.background = require("../../assets/passportau.png");
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/passportsymbol.png");
          this.currentSymbol2 = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/passport.mp4");
          this.videoUrl2 = require("../../assets/TMFA.mp4");
        }
        else if(this.showDiv2){
          this.showDiv1 = false;
          this.showDiv2 = false;
          this.showDiv3 = true;
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/TMFA.mp4");
          this.background2 = require("../../assets/IDcardau.png");
          this.currentSymbol2 = require("../../assets/IDcardsymbol.png");
          this.videoUrl2 = require("../../assets/IDcard.mp4");
        }
        else if(this.showDiv3){
          this.showDiv1 = true;
          this.showDiv2 = false;
          this.showDiv3 = false;
          this.background = require("../../assets/IDcardau.png");
          this.currentSymbol = require("../../assets/IDcardsymbol.png");
          this.videoUrl1 = require("../../assets/IDcard.mp4");
          this.background2 = require("../../assets/passportau.png");
          this.currentSymbol2 = require("../../assets/passportsymbol.png");
          this.videoUrl2 = require("../../assets/passport.mp4");
        }
      },
      change4(e){
        const myDiv = this.$refs.myDiv;
        this.x = e.pageX;
        let DivoffsetX = document.getElementById('Navline').offsetLeft;
        const length = this.x - DivoffsetX;
        let Divlength = document.getElementById('Navline').offsetWidth;
        let Divlengthslash3 = Divlength / 3;
        console.log(Divlength);
        console.log(Divlengthslash3);
        console.log(length);

        if(length > Divlengthslash3 && length < Divlengthslash3 * 2){
          this.showDiv1 = false;
          this.showDiv2 = true;
          this.showDiv3 = false;
          this.background = require("../../assets/passportau.png");
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/passportsymbol.png");
          this.currentSymbol2 = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/passport.mp4");
          this.videoUrl2 = require("../../assets/TMFA.mp4");
        }
        else if(length > Divlengthslash3 * 2){
          this.showDiv1 = false;
          this.showDiv2 = false;
          this.showDiv3 = true;
          this.background = require("../../assets/TMFAsafe.png");
          this.currentSymbol = require("../../assets/TMFAsymbol.png");
          this.videoUrl1 = require("../../assets/TMFA.mp4");
          this.background2 = require("../../assets/IDcardau.png");
          this.currentSymbol2 = require("../../assets/IDcardsymbol.png");
          this.videoUrl2 = require("../../assets/IDcard.mp4");
        }
        else if(length > 0 && length < Divlengthslash3){
          this.showDiv1 = true;
          this.showDiv2 = false;
          this.showDiv3 = false;
          this.background = require("../../assets/IDcardau.png");
          this.currentSymbol = require("../../assets/IDcardsymbol.png");
          this.videoUrl1 = require("../../assets/IDcard.mp4");
          this.background2 = require("../../assets/passportau.png");
          this.currentSymbol2 = require("../../assets/passportsymbol.png");
          this.videoUrl2 = require("../../assets/passport.mp4");
        }
      },
      Clicked1(){
        this.currentIOS = require("../../assets/iOSBlack.png");
        this.isClicked1 = false;
        this.bgColor1 = "white";
      },
      Unclicked1(){
        this.currentIOS = require("../../assets/iOSWhite.png");
        this.isClicked1 = true;
        this.bgColor1 = "black";
      },
      Clicked2(){
        this.currentAndroid = require("../../assets/AndroidBlack.png");
        this.currentHarmony = require("../../assets/HarmonyBlack.png");
        this.isClicked2 = false;
        this.bgColor2 = "white";
      },
      Unclicked2(){
        this.currentAndroid = require("../../assets/AndroidWhite.png");
        this.currentHarmony = require("../../assets/HarmonyWhite.png");
        this.isClicked2 = true;
        this.bgColor2 = "black";
      },
      Outed1(){
        this.currentIOS = require("../../assets/iOSWhite.png");
        this.isClicked1 = true;
        this.bgColor1 = "black";
      },
      Outed2(){
        this.currentAndroid = require("../../assets/AndroidWhite.png");
        this.currentHarmony = require("../../assets/HarmonyWhite.png");
        this.isClicked2 = true;
        this.bgColor2 = "black";
      },
      scrollToSection(sectionId){
        const section = this.$refs[sectionId];
        section.scrollIntoView({ behavior : 'smooth' });
      },
      Hovered1(){
        this.showimg1 = require("../../assets/BigWechat.png");
        this.img1isHover = true;
        this.img1isnotHover = false;
      },
      Unhovered1(){
        this.showimg1 = require("../../assets/SmallWechat.png");
        this.img1isHover = false;
        this.img1isnotHover = true;
      },
      Hovered2(){
        this.showimg2 = require("../../assets/Bigmail.png");
        this.img2isHover = true;
        this.img2isnotHover = false;
      },
      Unhovered2(){
        this.showimg2 = require("../../assets/Smallmail.png");
        this.img2isHover = false;
        this.img2isnotHover = true;
      },
      Navmouseover1(){
        this.bkgColor1 = "#5e84d9";
        this.textcolor1 = "white";
      },
      Navmouseout1(){
        this.bkgColor1 = "#f3f7ff";
        this.textcolor1 = "#5e84d9";
      },
      Navmousedown1(){
        this.bkgColor1 = "#3f6bce";
        this.textcolor1 = "white";
      },
      Navmouseup1(){
        this.bkgColor1 = "#f3f7ff";
        this.textcolor1 = "#5e84d9";
      },
      Navmouseover2(){
        this.bkgColor2 = "#5e84d9";
        this.textcolor2 = "white";
      },
      Navmouseout2(){
        this.bkgColor2 = "#f3f7ff";
        this.textcolor2 = "#5e84d9";
      },
      Navmousedown2(){
        this.bkgColor2 = "#3f6bce";
        this.textcolor2 = "white";
      },
      Navmouseup2(){
        this.bkgColor2 = "#f3f7ff";
        this.textcolor2 = "#5e84d9";
      },
      Navmouseover3(){
        this.bkgColor3 = "#5e84d9";
        this.textcolor3 = "white";
      },
      Navmouseout3(){
        this.bkgColor3 = "#f3f7ff";
        this.textcolor3 = "#5e84d9";
      },
      Navmousedown3(){
        this.bkgColor3 = "#3f6bce";
        this.textcolor3 = "white";
      },
      Navmouseup3(){
        this.bkgColor3 = "#f3f7ff";
        this.textcolor3 = "#5e84d9";
      },
      Navmouseover4(){
        this.bkgColor4 = "#5e84d9";
        this.textcolor4 = "white";
      },
      Navmouseout4(){
        this.bkgColor4 = "#f3f7ff";
        this.textcolor4 = "#5e84d9";
      },
      Navmousedown4(){
        this.bkgColor4 = "#3f6bce";
        this.textcolor4 = "white";
      },
      Navmouseup4(){
        this.bkgColor4 = "#f3f7ff";
        this.textcolor4 = "#5e84d9";
      },
      Limgmouseover(){
        this.leftimg = require("../../assets/leftbutton2.png");
      },
      Rimgmouseover(){
        this.rightimg = require("../../assets/rightbutton2.png");
      },
      Limgmouseout(){
        this.leftimg = require("../../assets/leftbutton1.png");
      },
      Rimgmouseout(){
        this.rightimg = require("../../assets/rightbutton1.png");
      },
      Limgmousedown(){
        this.leftimg = require("../../assets/leftbutton3.png");
      },
      Rimgmousedown(){
        this.rightimg = require("../../assets/rightbutton3.png");
      },
      Limgmouseup(){
        this.leftimg = require("../../assets/leftbutton1.png");
      },
      Rimgmouseup(){
        this.rightimg = require("../../assets/rightbutton1.png");
      },
      startmouseover(){
        this.isHover = true;
      },
      startmouseout(){
        this.isHover = false;
      },
      startmousedown(){
        this.isHover = true;
      },
      startmouseup(){
        this.isHover = false;
      },
      startmouseover1(){
        this.startbkg = "rgba(0,0,0,0.4)";
      },
      startmouseout1(){
        this.startbkg = "rgba(0,0,0,0.1)";
      },
      startmousedown1(){
        this.startbkg = "rgba(0,0,0,0.6)";
      },
      startmouseup1(){
        this.startbkg = "rgba(0,0,0,0.1)";
      },
      startmouseover2(){
        this.isHover2 = true;
      },
      startmouseout2(){
        this.isHover2 = false;
      },
      startmousedown2(){
        this.isHover2 = true;
      },
      startmouseup2(){
        this.isHover2 = false;
      },
      startmouseover3(){
        this.startbkg2 = "rgba(0,0,0,0.4)";
      },
      startmouseout3(){
        this.startbkg2 = "rgba(0,0,0,0.1)";
      },
      startmousedown3(){
        this.startbkg2 = "rgba(0,0,0,0.6)";
      },
      startmouseup3(){
        this.startbkg2 = "rgba(0,0,0,0.1)";
      },
      Zixunmouseover(){
        this.bkgColor5 = "#5E84D9";
      },
      Zixunmouseout(){
        this.bkgColor5 = "#5D80D5";
      },
      Zixunmousedown(){
        this.bkgColor5 = "#3F6BCE";
      },
      Zixunmouseup(){
        this.bkgColor5 = "#5D80D5";
      },
      playVideo1(){
        this.videoUrl = this.videoUrl1;
        this.showVideo = true;
        this.videoStyle = {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999
        };
      },
      playVideo2(){
        this.videoUrl = this.videoUrl2;
        this.showVideo = true;
        this.videoStyle = {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999
        };
      },
      pauseVideo(){
        this.$refs.videoPlayer.pause();
      },
      closeVideo(){
        console.log("completed");
        this.$refs.videoPlayer.pause();
        this.showVideo = false;
      },
      jump1(){
        window.open('http://www.eidlink.com', '_blank')
      },
      jump2(){
        window.open('https://eid.cn/', '_blank')
      },
      jump3(){
        window.open('http://www.dlcapitals.com', '_blank')
      },
      jump4(){
        window.open('http://www.yuanhecapital.com/a/dynamic', '_blank')
      },
      jump5(){
        window.open('https://www.did-a.org.cn', '_blank')
      },
      jump6(){
        window.open('https://www.beijingfintech.ac.cn', '_blank')
      },
    },
  };
</script>

<style scoped lang="less">
  .page {
    padding-top: 74px;
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    a{
      text-decoration: none;
    }
    .pad {
      padding: 0 35px;
    }
    .Fixed {
      position: fixed;
      top: 0px;//这个需要根据实际情况来调整
      z-index: 3;
    }
    .section100 {
      padding: 62px 0 466px;
      background-image: url('../../assets/firstpkg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 545px;
    }
    .image_200 {
      margin-left: 360px;
      width: 26px;
      height: 14px;
    }
    .image_300 {
      margin-left: 34px;
      width: 25px;
      height: 16px;
    }
    .image100 {
      width: 145px;
      height: 70px;
    }
    .pos100 {
      position: absolute;
      left: 318px;
      top: 57px;
    }
    .group_200 {
      margin-left: 38px;
    }
    .font_100 {
      font-size: 32px;
      font-family: Alimama ShuHeiTi;
      line-height: 48px;
      font-weight: 700;
      color: #5e84d9;
    }
    .group_300 {
      margin-top: 28px;
    }
    .space-x-3400 > *:not(:first-child) {
      margin-left: 34px;
    }
    .space-y-1600 > *:not(:first-child) {
      margin-top: 3px;
    }
    .image_400 {
      width: 23px;
      height: 23px;
    }
    .group_400 {
      text-align: center;
      height: 126px;
    }
    .font_200 {
      font-size: 28px;
      font-family: PingFang SC;
      line-height: 36px;
      color: #000000;
      padding-left: 30px;
      font-weight: 400;
    }
    .font_202 {
      font-size: 28px;
      font-family: PingFang SC;
      line-height: 36px;
      color: #000000;
    }
    .font_201 {
      font-size: 28px;
      font-family: PingFang SC;
      line-height: 36px;
      color: #000000;
      font-weight: 400;
      padding-left: 37px;
    }
    .text100 {
      letter-spacing: 4px;
    }
    .text-wrapper100 {
      margin-top: 40px;
      padding: 20px 0;
      background-color: #5d80d5;
      border-radius: 52px;
      filter: drop-shadow(0px 4px 2px #00000040);
      overflow: hidden;
      width: 224px;
    }
    .text_200 {
      color: #ffffff;
      line-height: 26px;
    }
    .group_500 {
      margin: 419px 109px 0 38px;
      padding: 6px 26px 70px;
      width: 213px;
    }
    .image_600 {
      margin-left: 36px;
      width: 39px;
      height: 20px;
    }
    .image_700 {
      margin-left: 50px;
      width: 37px;
      height: 23px;
    }
    .image_500 {
      width: 213px;
      height: 102px;
    }
    .pos_200 {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .group_3 {
      width: 962px;
      .group_4 {
        padding-left: 83px;
        padding-right: 80px;
        .text-wrapper_2 {
          padding: 14px 0;
          flex: 1 1 199px;
          border-radius: 0px 0px 0px 32px;
          width: 199px;
          height: 45px;
          border-right: solid 1px #5e84d9;
        }
        .text-wrapper_3 {
          flex: 1 1 199px;
          padding: 14px 0;
          width: 200px;
          height: 45px;
          border-right: solid 1px #5e84d9;
        }
        .font_3 {
          font-size: 18px;
          font-family: PingFang SC;
          line-height: 16.5px;
        }
        .text-wrapper_4 {
          padding: 14px 0;
          flex: 1 1 199px;
          border-radius: 0px 0px 32px 0px;
          width: 199px;
          height: 45px;
        }
      }
      .group_5 {
        margin-top: 58px;
      }
      .group_6 {
        margin-top: 58px;
        .group_7 {
          padding-top: 4px;
          width: 295px;
          .outerborder {
            filter: drop-shadow(0px 4px 12px #5e84d980);
          }
          .section_2 {
            margin-left: 4px;
            padding: 59px 3px 18px;
            border-radius: 38px;
            background-image: url('../../assets/whitebkg.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 291px;
            .section_4 {
              padding: 78px 0;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .image-wrapper {
                padding: 35px 0;
                border-radius: 50%;
                width: 133px;
                border: solid 3px #ffffff;
                .image_7 {
                  width: 57px;
                  height: 57px;
                }
              }
            }
            .text_7 {
              color: #555555;
              font-weight: 600;
            }
          }
          .space-y-20 {
            & > *:not(:first-child) {
              margin-top: 20px;
            }
          }
          .image_6 {
            width: 59px;
            height: 60px;
          }
          .pos_2 {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .group_8 {
          margin-top: 20px;
        }
        .space-x-16 {
          & > *:not(:first-child) {
            margin-left: 16px;
          }
        }
        .group_9 {
          margin-top: 16px;
          .text_4 {
            line-height: 17px;
          }
        }
        .section_3 {
          background-color: #fdca58;
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }
        .font_6 {
          font-size: 18px;
          font-family: PingFang SC;
          line-height: 16.5px;
          color: #4e5969;
        }
        .text_5 {
          margin-top: 24px;
          line-height: 21px;
        }
        .group_10 {
          margin-top: 24px;
        }
        .group_11 {
          margin-top: 20px;
        }
        .font_7 {
          font-size: 18px;
          font-family: PingFang SC;
          line-height: 18px;
          color: #4e5969;
        }
        .group_12 {
          margin-top: 20px;
        }
        .text_6 {
          margin-top: 22px;
        }
        .group_13 {
          margin-top: 24px;
        }
        .group_14 {
          margin-top: 20px;
        }
        .group_15 {
          margin-top: 20px;
        }
      }
      .group_16 {
        margin-top: 84px;
        padding: 0 9px 33px;
        .section_5 {
          background-color: #f2f2f2;
          border-radius: 5px;
          width: 676px;
          height: 6px;
          .section_6 {
            background-color: #5e84d9;
            border-radius: 5px;
            width: 225px;
            height: 6px;
            position:relative;
            left: 0px;
          }
          .left1 {
            left: 0px;
          }
          .left2 {
            left: 225px;
          }
          .left3 {
            left: 450px;
          }
        }
      }
      .space-x-18 {
        & > *:not(:first-child) {
          margin-left: 18px;
        }
        .image_8 {
          width: 48px;
          height: 49px;
        }
      }
    }
    .section_7 {
      padding: 58px 438px 133px;
      background-color: #f3f7ff;
    }
    .space-y-82 {
      & > *:not(:first-child) {
        margin-top: 82px;
      }
    }
    .space-y-22 {
      & > *:not(:first-child) {
        margin-top: 22px;
      }
      .text_8 {
        line-height: 30px;
      }
      .text_9 {
        line-height: 29px;
      }
      .text_10 {
        line-height: 30px;
      }
      .text_12 {
        line-height: 30px;
      }
      .text_38 {
        line-height: 30px;
      }
      .text_39 {
        line-height: 30px;
      }
    }
    .font_4 {
      font-size: 32px;
      font-family: Alimama ShuHeiTi;
      line-height: 29.5px;
      font-weight: 700;
      color: #1f307a;
    }
    .group_17 {
      margin-top: 58px;
      width: 997px;
      .group_18 {
        margin-top: 74px;
        .group_19 {
          margin: 6px 0;
          .text_11 {
            color: #5e84d9;
          }
        }
        .space-y-68 {
          & > *:not(:first-child) {
            margin-top: 68px;
          }
        }
        .section_8 {
          background-color: #d7d7d7;
          width: 1px;
          height: 300px;
        }
        .section_9 {
          background-color: #5d80d5;
          width: 6px;
          height: 75px;
        }
        .marginTop1 {
          margin-top: 0px;
        }
        .marginTop2 {
          margin-top: 75px;
        }
        .marginTop3 {
          margin-top: 150px;
        }
        .marginTop4 {
          margin-top: 225px;
        }
      }
      .space-x-134 {
        & > *:not(:first-child) {
          margin-left: 134px;
        }
      }
      .image_10 {
        width: 484px;
        height: 416px;
      }
    }
    .space-y-88 {
      & > *:not(:first-child) {
        margin-top: 88px;
      }
    }
    .font_5 {
      font-size: 22px;
      font-family: PingFang SC;
      line-height: 20.5px;
      color: #333333;
      font-weight: 500;
    }
    .group_20 {
      overflow-x: hidden;
    }
    .view {
      margin-top: 58px;
    }
    .space-y-54 {
      & > *:not(:first-child) {
        margin-top: 54px;
      }
    }
    .group_29 {
      margin-top: 80px;
      .space-y-79 {
        & > *:not(:first-child) {
          margin-top: 79px;
        }
        .section_25 {
          padding: 58px 0px 83px 0px;
          background-color: #f3f7ff;
          .group_30 {
            padding-bottom: 16px;
            .pos_93 {
              position: absolute;
              left: 0;
              top: 10px;
            }
            .pos_94 {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }
        .space-y-64 {
          & > *:not(:first-child) {
            margin-top: 64px;
          }
        }
        .group_35 {
          width: 600px;
          .group_36 {
            padding-top: 50px;
            .section_36 {
              background-color: #84b2ff;
              border-radius: 50%;
              width: 197px;
              height: 197px;
            }
            .image_38 {
              width: 197px;
              height: 248px;
            }
            .pos_96 {
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }
          .group_37 {
            margin-top: 40px;
            .text_40 {
              color: #383b46;
              font-size: 36px;
              font-family: Alimama ShuHeiTi;
              font-weight: 700;
              line-height: 33.5px;
            }
            .image_39 {
              width: 163px;
              height: 165px;
            }
            .group_38 {
              margin: 2px 0 2px 54px;
              width: 383px;
              .section_37 {
                padding: 16px 0;
                border-radius: 10px;
                border-image-slice: 1;
                border: solid 2px #6ddcff;
                .image_40 {
                  margin-left: 128px;
                  width: 31px;
                  height: 31px;
                }
                .text_41 {
                  margin-right: 134px;
                  font-size: 25px;
                  line-height: 19px;
                }
              }
              .section_38 {
                padding: 12px 16px 10px 20px;
                border-radius: 10px;
                border-image-slice: 1;
                border: solid 2px #6ddcff;
                .image_41 {
                  width: 36px;
                  height: 36px;
                }
                .text_42 {
                  line-height: 18.5px;
                }
                .image_42 {
                  width: 35px;
                  height: 35px;
                }
                .text_43 {
                  line-height: 18.5px;
                }
              }
              .font_15 {
                font-size: 24px;
                font-family: Alibaba PuHuiTi 2;
                line-height: 18px;
              }
              .clicked {
                color: #ffffff;
              }
            }
            .space-y-34 {
              & > *:not(:first-child) {
                margin-top: 34px;
              }
            }
          }
          .space-y-26 {
            & > *:not(:first-child) {
              margin-top: 26px;
            }
          }
          .text_44 {
            margin-top: 192px;
          }
        }
      }
      .group_39 {
        width: 1137px;
        .image_43 {
          width: 185px;
          height: 69px;
        }
        .image_44 {
          width: 186px;
          height: 69px;
        }
        .image_45 {
          width: 187px;
          height: 69px;
        }
        .img_border {
          border: solid 0.5px #f2f2f2;
        }
        .image_46 {
          width: 186px;
          height: 64px;
       }
      }
    }
    .space-y-38 {
      & > *:not(:first-child) {
        margin-top: 38px;
      }
    }
    .space-x-122 {
      & > *:not(:first-child) {
        margin-left: 122px;
      }
    }
    .group_40 {
      margin-top: 66px;
      // width: 884.5px;
    }
    .font_16 {
      font-size: 24px;
      font-family: Alimama ShuHeiTi;
      line-height: 22.5px;
      font-weight: 700;
      color: #5e84d9;
    }
    .space-y-14 {
      .group_41 {
        & > *:not(:first-child) {
          margin-top: 14px;
        }
        .image_47 {
          width: 40px;
          height: 40px;
        }
        .image_48 {
          width: 31px;
          height: 38px;
        }
        .font_17 {
          font-size: 16px;
          font-family: PingFang SC;
          line-height: 15px;
          font-weight: 600;
          color: #1f307a;
        }
      }
      .space-x-80 {
          padding-left: 80px;
      }
      .font_18 {
        font-size: 12px;
        font-family: PingFang SC;
        line-height: 34px;
        color: #666666;
        font-weight: 400;
      }
      .text_45 {
        line-height: 9px;
      }
      .font_20 {
        font-size: 13px;
        font-family: PingFang SC;
        line-height: 34px;
        color: #666666;
        font-weight: 400;
      }
    }
    .section_39 {
      margin-top: 76px;
      padding: 21px 0 34px;
      background-color: #5e84d9;
      .font_19 {
        font-size: 14px;
        font-family: PingFang SC;
        line-height: 13px;
        color: #ffffff;
      }
      .text_48 {
        font-size: 13px;
        line-height: 12px;
      }
      .text_46 {
        margin-left: 22px;
      }
      .image_49 {
        margin-left: 38px;
        width: 20px;
        height: 20px;
      }
      .text_47 {
        margin-left: 29px;
      }
    }
    .space-y-24 {
      & > *:not(:first-child) {
        margin-top: 24px;
      }
      .image_9 {
        width: 199px;
        height: 81px;
      }
    }
    .size {
      width:1043px;
    }
    .section10 {
      margin-top: 106px;
      padding: 58px 307px 107px;
      background-color: #f3f7ff;
      .spacey22 {
        & > *:not(:first-child) {
          margin-top: 22px;
        }
        .font5 {
          font-size: 32px;
          font-family: Alimama ShuHeiTi;
          line-height: 30px;
          font-weight: 700;
          color: #1f307a;
        }
        .text14 {
          line-height: 29.5px;
        }
        .text15 {
          line-height: 29.5px;
        }
      }
      .spacex32 {
        & > *:not(:first-child) {
          margin-left: 32px;
        }
        .group20 {
          // flex: 1 1 414px;
          padding-top: 70px;
          .section11 {
            padding: 71px 34px 58px;
            background-color: #ffffff;
            border-radius: 38px;
            .group21 {
              padding: 0 2px;
              .group22 {
                margin-right: 18px;
              }
            }
            .margintop14 {
              margin-top: 14px;
            }
            .margintop17 {
              margin-top: 17px;
            }
            .text21 {
              margin-top: 8px;
              margin-left: 16px;
            }
          }
          .image-wrapper18 {
            background-color: #ffffff;
            border-radius: 56px;
            width: 112px;
            height: 112px;
            border: solid 6px #f3f7ff;
            .image28 {
              width: 48px;
              height: 48px;
              position: relative;
              top: 25px;
            }
          }
          .pos3 {
            position: absolute;
            left: 47px;
            top: 15px;
          }
          .font8 {
            font-size: 22px;
            font-family: PingFang SC;
            line-height: 33px;
            color: #333333;
            font-weight: 500;
          }
          .section13 {
            background-color: #fdca58;
            border-radius: 50%;
            width: 8px;
            height: 8px;
          }
          .font3 {
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 21px;
            color: #4e5969;
          }
          .text16 {
            margin-left: 8px;
          }
          .spacex6 {
            & > *:not(:first-child) {
              margin-left: 8px;
            }
          }
          .image-wrapper19 {
            padding: 7px 0 12px;
            background-color: #ffffff;
            border-radius: 56px;
            width: 112px;
            height: 112px;
            border: solid 6px #f3f7ff;
            .image29 {
              width: 64px;
              height: 64px;
              position: relative;
              top: 10px;
            }
          }
          .image-wrapper20 {
            padding: 7px 0 12px;
            background-color: #ffffff;
            border-radius: 56px;
            width: 112px;
            height: 112px;
            border: solid 6px #f3f7ff;
            .image30 {
              width: 56px;
              height: 56px;
              position: relative;
              top: 15px;
            }
          }
          .pos5 {
            position: absolute;
            left: 47px;
            top: 15px;
          }
        }
      }
    }
    .spacey84 {
      & > *:not(:first-child) {
        margin-top: 84px;
      }
    }

    .img_1 {
      width: 310px;
      overflow: scroll;
      display: inline-block;
      margin-right: 20px;
    }
    .img_1:last-child {
      margin-right: 20px;
    }

    .imgList {
      position: relative;
      align-items: center;
      justify-content: center;
      overflow-x: auto;
      white-space: nowrap;
    }
    ul {
      padding-left: 0%;
      margin-left: 20px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .imgList::-webkit-scrollbar {
      display: none;
    }
  
    .slide-enter-active, .slide-leave-active{
      transition: all .5s ease;
    }
    .slide-enter, .slide-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }
    .showimgsize1 {
      width: 248px;
      height: auto;
    }
    .showimgsize2 {
      width: 386px;
      height: auto;
    }
    .circle-container {
      width: 380px;
      height: 380px;
      border-radius: 50%;
      margin: 0 auto 0 auto;
      position: absolute;
    }
    .nav_circle{
      width: 0px;
      height: 380px;
      float: left;
      position: absolute;
      top: 0px;
      left: 203px;
      text-align: center;
    }
    .r1{
      transform:rotate(0deg) skew(0deg) scale(1);
      -webkit-transform: rotate(0deg) skew(0deg) scale(1);
      -o-transform: rotate(0deg) skew(0deg) scale(1);
      animation: rotate 20s linear infinite;
      -webkit-animation: rotate 20s linear infinite;
      -moz-animation: rotate 20s linear infinite;
      -o-animation: rotate 20s linear infinite;
      -webkit-transform: rotate(180deg);
    }
    .circle-container:hover .r1 {
      animation-play-state: paused;
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
    }
    .circle-container:hover .circle1 {
      animation-play-state: paused;
    }
    .circle-container:hover .circle2 {
      animation-play-state: paused;
    }
    .circle1 {
      animation: rotate2 20s linear infinite;
    }
    .circle2{
      animation: rotate2 20s linear infinite;
    }

    @keyframes rotate {
      0%{
        transform: rotate(0deg) skew(0deg) scale(1);
        -webkit-transform: rotate(0deg) skew(0deg) scale(1);
        -o-transform: rotate(0deg) skew(0deg) scale(1);
      }
      100%{
        transform: rotate(360deg) skew(0deg) scale(1);
        -webkit-transform: rotate(360deg) skew(0deg) scale(1);
        -o-transform: rotate(360deg) skew(0deg) scale(1);
      }
    }
    @keyframes rotate2 {
      0%{
        transform: rotate(0deg) skew(0deg) scale(1);
        -webkit-transform: rotate(0deg) skew(0deg) scale(1);
        -o-transform: rotate(0deg) skew(0deg) scale(1);
      }
      100%{
        transform: rotate(-360deg) skew(0deg) scale(1);
        -webkit-transform: rotate(-360deg) skew(0deg) scale(1);
        -o-transform: rotate(-360deg) skew(0deg) scale(1);
      }
    }
    .clicked{
      color: #000000;
    }
    .imgcircle{
      width: 378px;
      height: 378px;
    }
    .container {
      width: 100%;
      height: 100%;
      background-color:#000000;
    }
    video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color:#000000;
    }
    .close-button{
      position: absolute;
      top: 0;
      right: 0;
    }
    .divsize {
      height: 17px;
    }
    .space-y-188 > *:not(:first-child) {
      margin-top: 188px;
    }
    .space-y-52 > *:not(:first-child) {
      margin-top: 52px;
    }
    .section_900 {
      padding: 46px 0;
      // flex: 1 1 233px;
      border-radius: 50%;
      width: 233px;
      height: 233px;
      border: solid 4px #ceddff;
    }
    .section_12 {
      background-color: #84b2ff;
      border-radius: 50%;
      width: 133px;
      height: 133px;
    }
    .section_11 {
      background-color: #2c2c2f;
      border-radius: 8px;
      width: 76px;
      height: 47px;
    }
    .pos_17 {
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translateX(-50%);
    }
    .image-wrapper100 {
      padding: 50px 0;
      background-image: url('../../assets/dcfafd822843ee8121b399b771c1ecd7.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 133px;
    }
    .pos_18 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .image_25 {
      border-radius: 11px;
      width: 32px;
      height: 33px;
    }
    .image_22 {
      width: 83px;
      height: 167px;
    }
    .pos_16 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .section_10 {
      margin: 4px 0 4px 23px;
      padding: 36px 0;
      border-radius: 50%;
      width: 225px;
      height: 225px;
      border: solid 21px #ceddff;
    }
    .image-wrapper_2 {
      border-radius: 8px;
      width: 134px;
    }
    .image_23 {
      width: 114px;
      height: 115px;
    }
    .image_24 {
      width: 76px;
      height: 76px;
    }
    .pos_19 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .group_290 {
      padding-left: 33px;
      padding-right: 29px;
    }
    .space-y-140 > *:not(:first-child) {
      margin-top: 14px;
    }
    .text_14 {
      color: #383b46;
      font-size: 36px;
      font-family: Alimama ShuHeiTi;
      font-weight: 700;
      line-height: 33.5px;
      padding: 24px;
    }
    .text-wrapper_5 {
      padding: 20px 0;
      background-color: #090914;
      border-radius: 10px;
      border-image-slice: 1;
      width: 440px;
      border: solid 2px #6fd9ff;
    }
    .text_15 {
      color: #fffffff5;
      font-size: 28px;
      font-family: Alibaba PuHuiTi 2;
      line-height: 25.5px;
    }
    .font_8 {
      font-size: 24px;
      font-family: Alimama ShuHeiTi;
      line-height: 22.5px;
      font-weight: 700;
      color: #5e84d9;
      text-align: center;
    }
    .padleft {
      padding-left: 10px;
    }
    .width1{
      width: 195px;
    }
    .width2{
      width: 147px;
    }
    .width3{
      width: 188px;
    }
    .width4{
      width: 124px;
    }
    .cursors {
      cursor: pointer;
    }
  }
</style>