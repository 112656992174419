import Vue from 'vue';
import App from './app.vue';
import router from './router';
import 'amfe-flexible/index';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
Vue.config.productionTip = false;

if (isMobile) {
  window.location.href = 'https://www.eidtokencloud.com/mobile/index.html';
} else {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
}